import { LucideShieldPlus } from 'lucide-react'

import { useAuth } from '~/components/Auth/AuthProvider'

import { Tooltip } from '~/components/Common/Tooltip'
import Icon from '~/components/Icon'
import { GoogleIcon } from '~/components/Icon/GoogleIcon'
import { useLanguage } from '~/components/Language/LanguageProvider'
import AuthLayoutBox from '~/components/Layout/AuthLayout/AuthLayoutBox'
import { Button } from '~/components/ui/button'
import { COLORS } from '~/constants/colors'

export const LoginPage = () => {
    const { googleSignIn, appleSignIn } = useAuth()
    const { t } = useLanguage()
    return (
        <>
            <AuthLayoutBox title={'Masuk'}>
                <div id="login" className="w-full relative bg-transparent">
                    <div className="grid grid-cols-1 gap-5">
                        {/* <Button
                            type="outlined"
                            text={t('common.enter')}
                            fullWidth
                            onClick={showOtpLessWidget}></Button> */}
                        <Button className="bg-dark text-white" variant="ghost" size="lg" onClick={() => googleSignIn()}>
                            <GoogleIcon></GoogleIcon>
                            <div className="w-2"></div>
                            {t('common.login.google')}
                        </Button>
                        {/* <Button className="bg-dark text-white" variant="ghost" size="lg" onClick={() => appleSignIn()}>
                            <AppleIcon></AppleIcon>
                            <div className="w-2"></div>
                            {locale.id.common.login.apple}
                        </Button> */}

                        {/* <Button
                            type="outlined"
                            text={locale.id.common.login.apple}
                            backgroundColor="#000"
                            color="#fff"
                            icon={
                                <>
                                    <AppleIcon></AppleIcon>
                                    <div className="w-2"></div>
                                </>
                            }
                            fullWidth
                            onClick={appleSignIn}></Button> */}
                    </div>
                    <div className="text-center text-sm font-normal mt-5 flex items-center justify-center">
                        <LucideShieldPlus
                            fill={COLORS.SEMANTIC.GREEN['400']}
                            color={COLORS.MAIN.NEUTRALS.light}
                            className="mr-1"
                            size={24}
                        />
                        <p className="text-neutrals-900">{t('security_guarantee.title')}</p>
                    </div>
                </div>
            </AuthLayoutBox>
            <a href="https://go.kelola.co/wa" target="_blank" rel="noreferrer">
                <div
                    className="fixed right-6 bottom-6 rounded-full bg-green-400 hover:bg-green-500 transition-colors text-neutrals-light flex items-center justify-center p-3"
                    role="button">
                    <Tooltip content={'Chat on WhatsApp'} variant="information">
                        <div className="flex items-center justify-center">
                            <Icon width={32} name="chat-outline"></Icon>
                        </div>
                    </Tooltip>
                </div>
            </a>
        </>
    )
}

export default LoginPage
